<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Forgot Password? 🔒
          </b-card-title>
          <b-card-text class="mb-2" v-if="!emailReset">
            Enter your email and we'll send you instructions to reset your password
          </b-card-text>

          <!-- form -->
          <validation-observer ref="forgotPassForm" v-slot="{ invalid }" v-if="!emailReset">
            <b-form class="auth-forgot-password-form mt-2" @submit.prevent="sendResetLink" >
              <b-form-group label="Email" label-for="forgot-password-email" >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                class="mt-1"
                :disabled="invalid || isSendingLinkReset"
              >

                <span v-if="isSendingLinkReset">
                  <b-spinner variant="success" style="width: 1.5rem; height: 1.5rem;" /> Sending reset link...
                </span>
                <span v-if="!isSendingLinkReset">
                  Send reset link <feather-icon icon="MailIcon" />
                </span>
              </b-button>
            </b-form>
          </validation-observer>

          <validation-observer ref="forgotPassForm" v-slot="{ invalid }" v-else>
            <b-form class="auth-forgot-password-form mt-2" @submit.prevent="sendResetLink" >
              <b-card-text class="mb-2">
                {{messageEmailReset}}
              </b-card-text>
                <b-row class="text-center">
                  <b-col md="6">
                      <b-button
                      variant="danger"
                      class="mt-1"
                      :disabled="invalid || isSendingLinkReset"
                      @click="undoEmailReset"
                    ><span>Try <br> another email <feather-icon icon="MailIcon" /></span>
                    </b-button>
                  </b-col>
                  <b-col md="6">
                    <b-button
                      type="submit"
                      variant="primary"
                      class="mt-1"
                      :disabled="invalid || isSendingLinkReset"
                    ><span>Resend<br>reset link <feather-icon icon="MailIcon" /></span>
                    </b-button>
                  </b-col>
                  <b-col v-if="isSendingLinkReset" class="pt-2 text-center">
                    <span>
                      <b-spinner variant="success" style="width: 1.5rem; height: 1.5rem;" /> Sending reset link...
                    </span>
                  </b-col>
                </b-row>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}" v-if="!isSendingLinkReset">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
            <span v-else><feather-icon icon="ChevronLeftIcon" /> Back to login</span>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from 'vuex'
import Logo from '@/modules/layouts/components/Logo.vue'
import { required, email } from '@validations'
import store from '@/store/index'
import { showAlertMessage } from '@/helpers/helpers'

// import { VueRecaptcha } from 'vue-recaptcha'
export default {
  components: {
    Logo,
    // VueRecaptcha
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      // sitekey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY
      isSendingLinkReset: false,

    }
  },
  computed: {
    ...mapState('auth', ['emailReset','messageEmailReset']),
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions('auth', ['signIn','sendResetPasswordLink']),
    ...mapMutations('auth', ['setEmailReset','setMessageEmailReset','undoEmailReset']),

    async sendResetLink() {
      const isValidForm = await this.$refs.forgotPassForm.validate()
      if(isValidForm){
        this.isSendingLinkReset = true
        const payload = { email: this.userEmail.trim(), resendemail: false } //con trim remuevo espacios vacios
        if(this.emailReset){
          payload.email = this.emailReset
          payload.resendemail = true
        }
        const { status, message } = await this.sendResetPasswordLink(payload)
        if(status && status != 'Expired'){
          this.setEmailReset(this.userEmail)
          this.setMessageEmailReset(message)
          this.userEmail = ''
          Object.assign(this.$data, this.$options.data())//limpio data o reset
          this.$refs.forgotPassForm.reset()
				  showAlertMessage( 'Success!', 'InfoIcon', message, 'success', 4000, 'bottom-right')
        } else showAlertMessage( "Sorry...", 'InfoIcon', message, 'danger', 4000, 'bottom-right')
        this.isSendingLinkReset = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
@media screen and (max-width: 767px){
   .auth-wrapper.auth-v2 .brand-logo {
      position: relative;
      left: 0;
      margin: 0 auto;
    }
  .auth-wrapper .brand-logo {
    display: flex;
    justify-content: center;
    // flex-direction: column;
  }

  }

.brand-logo svg {
  height: 125px;
  width: 250px;
}
</style>
